import React from "react";
import "../styles.css";

const Home = () => {
  return (
    <div style={{ maxWidth: "800px", margin: "auto", padding: "20px" }}>
    <nav style={{ textAlign: "center", marginBottom: "20px" }}>
  <a href="#about">About</a> | 
  <a href="#experience">Experience</a> | 
  <a href="#skills">Skills</a> | 
  <a href="#education">Education</a> | 
  <a href="#contact">Contact</a>
</nav>

      {/* Header Section */}
      <header style={{ textAlign: "center", marginBottom: "40px" }}>
        <h1>John Doe</h1>
        <h3>Full Stack Developer</h3>
        <p>Building modern and efficient web applications.</p>
      </header>

      {/* About Me Section */}
      <section id="about">
        <h2>About Me</h2>
        <p>
          I am a passionate web developer with experience in building responsive and dynamic web applications.
        </p>
      </section>

      {/* Work Experience Section */}
      <section id="experience">
        <h2>Work Experience</h2>
        <ul>
          <li>
            <strong>Software Engineer</strong> - Tech Company (2020 - Present)
            <p>Developed various web applications using React and Node.js.</p>
          </li>
          <li>
            <strong>Frontend Developer</strong> - Web Agency (2018 - 2020)
            <p>Built UI/UX components for client projects.</p>
          </li>
        </ul>
      </section>

      {/* Skills Section */}
      <section id="skills">
        <h2>Skills</h2>
        <ul>
          <li>JavaScript (React, Node.js)</li>
          <li>HTML & CSS</li>
          <li>REST APIs</li>
          <li>Database Management</li>
        </ul>
      </section>

      {/* Education Section */}
      <section id="education">
        <h2>Education</h2>
        <p><strong>Bachelor's in Computer Science</strong> - University XYZ (2014 - 2018)</p>
      </section>

      {/* Contact Section */}
      <section id="contact">
        <h2>Contact</h2>
        <p>Email: johndoe@example.com</p>
        <p>LinkedIn: linkedin.com/in/johndoe</p>
      </section>
    </div>
  );
};

export default Home;
